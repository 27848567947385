import React from 'react';

const IconRight = () => {
  return (
    <svg
      width='9'
      height='8'
      viewBox='0 0 9 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.35355 4.35355C8.54882 4.15829 8.54882 3.84171 8.35355 3.64645L5.17157 0.464466C4.97631 0.269204 4.65973 0.269204 4.46447 0.464466C4.2692 0.659728 4.2692 0.976311 4.46447 1.17157L7.29289 4L4.46447 6.82843C4.2692 7.02369 4.2692 7.34027 4.46447 7.53553C4.65973 7.7308 4.97631 7.7308 5.17157 7.53553L8.35355 4.35355ZM0 4.5H8V3.5H0L0 4.5Z'
        fill='#222222'
      />
    </svg>
  );
};

export default IconRight;
